import buildScript from "~/utils/build_script";
import { isServer } from "~/utils/env_check";

const loadRecruitics = async (enableRecruitics: boolean) => {
  if (isServer() || !enableRecruitics) return;

  if (window.rx) return;

  try {
    await buildScript("//jsv3.recruitics.com/partner/d056b859-3c00-11e7-8fe9-89f468934c80.js");
  } catch {
    console.error("Failed to load Recruitics");
  }
};

export default loadRecruitics;
